import React from 'react'
import Layout from 'components/layout/Main/MainLayout'
import SEO from 'components/seo'

const PrivacyPolicy = ({}) => {
  return (
    <Layout>
      <SEO title="Privacy Policy Page" />
      <section className="generic-section">
        <div className="container">
          <div className="title-section">
            <h1>Privacy Policy</h1>
            <h4>MILLENNIAL HOME LENDING, INC. PRIVACY POLICY</h4>
          </div>

          <div className="content-section">
            <p>
              In general, any person or business can visit us on the internet
              without telling us who they are or giving us personal information.
              At times, our business may require that we collect certain
              information from you, for instance: to register on our website,
              place an order, provide customer support, manage your
              communication preferences, or subscribe to our newsletter. We may
              supplement this information to complete a transaction or to
              provide a better service. This Privacy Policy describes in detail
              how we may collect and use your personal information, and the
              choices you have concerning our use of your information.
            </p>

            <p>
              PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING
              THE WEBSITE AT{' '}
              <a href="https://mhlending.us" target="_blank">
                https://mhlending.us
              </a>{' '}
              (the “Site”) OR OUR MORTGAGE LENDING SERVICES (the “Services”), OR
              BY SUBMITTING ANY INFORMATION OR MATERIALS TO OR THROUGH THE SITE
              OR OUR SERVICES, YOU AGREE TO OUR COLLECTION AND USE OF SUCH
              INFORMATION OR MATERIALS AS DESCRIBED HEREIN. IF YOU DO NOT AGREE
              TO ALL OF THE TERMS OF THE PRIVACY POLICY, OR TO OUR COLLECTION
              AND USE OF INFORMATION OR MATERIALS FROM YOU, DO NOT ACCESS OR USE
              THE SITE OR OUR SERVICES.{' '}
            </p>
            <h4>WHAT DOES THE PRIVACY POLICY APPLY TO?</h4>
            <p>
              This Site and the Services are operated by Millennial Home
              Lending, Inc. (“MHL”, “us”, or “our”). This Privacy Policy applies
              to the Site and our Services only. The Privacy Policy does not
              cover the privacy practices of any other company or any other
              website operated in whole or in part by us. This privacy policy
              applies only to information collected through our Site and
              Services and not to information collected offline.{' '}
            </p>
            <h4>WHAT INFORMATION DO WE COLLECT?</h4>
            <p>
              <b>We collect information you voluntarily disclose to us.</b> The
              Site and our Services allow you to complete forms for the purpose
              of applying for and creating an account, using our Services, or
              making general inquiries. By filling out these forms, you agree to
              send to us all the information you input into the forms, including
              the personal information you provide. As an example, the forms may
              prompt you to fill in your name, email address, telephone number,
              address, reason for contacting us, or payment information.{' '}
            </p>
            <p>
              We may associate data you voluntarily disclose to us with the data
              we collect when you visit the Site or use our Services, as well as
              with data obtained from outside sources. By voluntarily disclosing
              such information to us, you expressly permit us to use your
              information.
            </p>

            <p>
              The Site and our Services also link to our pages on various social
              networking sites, such as Facebook, Yelp, and LinkedIn. If you
              become a fan, follow us, or otherwise interact with us on these
              social networking sites, then we may be able to personally
              identify you because we may be able to see whatever information
              you make publicly available about yourself on that social
              networking site. By doing so, you expressly permit this use of
              your personal information by us.
            </p>
            <p>
              You should also be aware that if you include information such as
              your name in a comment on the Site or within the Services, such
              information becomes public information, meaning that you lose
              privacy rights you might have regarding that information.
            </p>

            <p>
              {' '}
              <b>
                We may collect information when you visit the Site or use our
                Services.
              </b>{' '}
              We may also use, collect, track, and store certain data in a form
              that does not, on its own, permit direct association with any
              specific individual. We may collect, use, transfer, and disclose
              non-personal information for any purpose. As an example, we may
              collect data when you visit our Site or use our Services including
              but not limited to: your IP address, the website that referred you
              to our Site or Services; the search terms you used to find our
              Site or Services; your device information such as operating
              system, browser type, and device type used to access our Site or
              Services; wireless network and your mobile carrier; session
              information including how you used our Site or Services, and
              cookies or other technologies such as pixel tags.
            </p>
            <p>
              We might use this non-personal information, for example, to better
              understand customer behaviors, improve our services and
              advertising, or to provide our customer with more useful
              information about our Site and Services.
            </p>
            <p>
              We might aggregate the information we collect in order to make use
              of it; aggregated data is considered non-personal information for
              purposes of this Privacy Policy.
            </p>

            <p>
              We might combine the data we collect with information from outside
              records (e.g. demographic information and additional contact
              information) that we have received in accordance with the law.
            </p>
            <h4>WHAT DO WE USE YOUR INFORMATION FOR?</h4>
            <p>
              Any of the information we collect from you may be used in one or
              more of the following ways:
            </p>

            <ul>
              <li>To register you for an account with the Site or Services.</li>
              <li>
                To provide and improve customer service – Your information helps
                us to more effectively respond to your customer service requests
                and support needs.
              </li>
              <li>
                To improve our Site and Services – Your information and usage
                information is used to better understand network behavior,
                trends, and detect potential outages and technical issues.
              </li>
              <li>
                To process transactions – Your information, whether public or
                private, will not be sold, exchanged, transferred, or given to
                any other company for any reason whatsoever, without your
                consent, other than for the express purpose of delivering the
                service requested.
              </li>
              <li>
                To send periodic emails – The email address you provide for
                order processing, may be used to send you information and
                updates pertaining to your order, in addition to receiving
                occasional company news, updates, related product or service
                information, etc.
              </li>
              <li>
                To fulfill your requests to access, purchase, and use our
                Services, and to provide you with the services you request from
                us, including by others involved in the fulfillment of your
                request.
              </li>
              <li>
                To provide you with notices such as communications about
                purchases and changes to our terms, conditions, and policies.
              </li>
              <li>
                To communicate with you – via telephone, email mail, SMS, or
                other means – as may be required in the context of our business
                relationship related to your access, purchase, or use of our
                Services
              </li>
              <li>
                To prevent fraud and spam – Your information may be used to
                prevent, detect, and investigate fraud, security breaches,
                potentially prohibited or illegal activities, protect our
                trademarks and enforce our Terms of Use.
              </li>
              <li>
                For internal purposes such as auditing, analysis to improve our
                Site or Services, or our advertising.
              </li>
              <li>For marketing purposes.</li>
            </ul>
            <p>
              We limit marketing to a reasonable and proportionate level and
              only send you communications which we believe may be of interest
              or relevance to you. You can stop direct marketing at any time by
              following the unsubscribe link you will find on all the email
              marketing messages we send you. If you unsubscribe from our
              marketing communications, we may be unable to notify you of
              tailored services or offers to meet your needs. If you unsubscribe
              from marketing communications from us, you will still receive
              operations and service messages from us regarding your account.
            </p>
            <h4>HOW DO WE PROTECT YOUR INFORMATION?</h4>
            <p>
              We take the security of the information we collect and maintain
              very seriously. To prevent unauthorized access or disclosures, we
              utilize physical, electronic, and procedural safeguards to protect
              all the information we collect and maintain, including any
              personal information you voluntarily provide.{' '}
            </p>
            <p>
              We implement a variety of security measures to maintain the safety
              of your personal information and to reduce the risk of accidental
              destruction, loss, unauthorized disclosures, alteration, or
              access, including:{' '}
            </p>
            <ul>
              <li>
                Password protection, encryption, and use of secure communication
                transmission software (known as “secure socket layering” or
                “SSL”) to protect our Site and Services;
              </li>
              <li>Using 128-bit encryption for all online access;</li>
              <li>
                Utilizing a firewall to block unauthorized entry into our Site;
              </li>
              <li>
                Placing confidentiality requirements on our employees and
                service providers;
              </li>
              <li>
                Destroying or permanently anonymizing personal information if it
                is no longer needed for the purposes for which it was collected;
              </li>
              <li>
                Following strict security procedures in the storage and
                disclosure of your personal information to prevent unauthorized
                access to it; and
              </li>
              <li>Physical access controls to our data centers.</li>
            </ul>
            <p>
              Please be aware that, although we endeavor to provide appropriate
              security for the information we collect and maintain, no security
              system can prevent all potential security breaches. While we take
              technical and organizational measures to safeguard your personal
              information, no transmission over the internet can ever be
              guaranteed to be secure. Therefore, we cannot guarantee the
              security of any personal information that you transfer over the
              internet to use and any such transmission is at your own risk.
            </p>
            <p>
              As the security of personal information depends in part on the
              security of the computer or device you use to communicate with use
              and the security you use to protect usernames and passwords, you
              should take steps to protect against unauthorized access to your
              password, computer, and web-enabled devices by signing off after
              using a shared computer, inserting a password on your web-enabled
              device, choosing a password that nobody else knows or can easily
              guess, keeping your password private, and periodically changing
              your password. You should never share your log in information with
              others. We are not responsible for any lost, stolen, or
              compromised passwords, or for any activity on your account via
              unauthorized password activity.
            </p>

            <p>
              As an additional security measure, the Site will automatically log
              you off after a period of inactivity. This reduces the risk of
              others accessing your information from your unattended computer.
            </p>
            <h4>HOW LONG DO WE STORE YOUR PERSONAL INFORMATION?</h4>
            <p>
              We will store your personal information for as long as is
              reasonably necessary for the purposes for which it was collected.
              Where your information is no longer needed, we will ensure that it
              is disposed of in a secure manner. In some circumstances we may
              store your personal information for longer periods of time, for
              instance where we are required to do so in accordance with legal,
              regulatory, tax, or accounting requirements.
            </p>
            <p>
              In certain circumstances, we may store your personal information
              for longer periods of time so that we have an accurate record of
              your dealings with us in the events of any complaints or
              challenges, or if we reasonably believe there is a prospect of
              litigation relating to your personal information or dealings.
            </p>

            <h4>DO WE USE COOKIES?</h4>
            <p>
              Our Site uses cookies. ‘Cookies’ are alphanumeric identifiers in
              the form of text files that are inserted and stored by your Web
              browser on your computer’s hard drive. We may set and access
              cookies on your computer to track and store identifying
              information about you. We also may gather information about you
              using cookie technology. For example, we may assign a cookie to
              you to limit the amount of times you see particular information.
              Please note that most Internet browsers will allow you to stop
              cookies from being stored on your computer and to delete cookies
              stored on your computer. If you choose to eliminate cookies, the
              full functionality of the Service may be impaired for you. We
              encode our cookies so that only we can interpret the information
              stored in them.
            </p>
            <h4>DO WE DISCLOSE ANY INFORMATION TO OUTSIDE PARTIES?</h4>
            <p>
              Generally, we do not share your information with outside parties;
              however, they may be circumstances where we are required to share
              information. For example, we share your personal information with
              third parties who help manage our business and deliver services,
              such as IT service providers. These third parties have agreed to
              confidentiality restrictions and have agreed to use any personal
              information we share with them, or which they collect on our
              behalf, solely for the purpose of providing the contracted service
              to us.
            </p>
            <p>
              We may share your personal information with government
              organizations, law enforcement, and regulators, to comply with all
              applicable laws, regulations, and request of law enforcement,
              regulatory, and other governmental agencies in response to legal
              process.
            </p>
            <p>
              We may share your personal information with banks and payment
              providers to authorize and complete credit card payments.
            </p>
            <p>
              We may share in aggregate, statistical form, non-personal
              information regarding the visitors to our Site and Services,
              traffic patterns, and usage with our partners, affiliates, or
              advertisers.
            </p>
            <p>
              If, in the future, we are acquired by or merged with another
              company, if substantially all our assets are transferred to
              another company, or as part of a bankruptcy proceedings, we may
              disclose or transfer the information we have collected from you to
              the acquiring company.
            </p>
            <h4>DO WE SHARE YOUR INFORMATION WITH THIRD PARTIES?</h4>
            <p>
              We do not sell, trade, rent, or otherwise transfer your personally
              identifiable information to third parties.
            </p>
            <p>
              This Privacy Policy only applies to information collected by us.
              This Privacy Policy does not apply to the practices of companies
              or other parties that we do not own or control, or their employees
              that we do not manage. By using our Site and Services, you agree
              that we shall have no responsibility or liability for the wrongful
              use or disclosure of your personal information by any third party.
              Nonetheless, we seek to protect the integrity of our Site and
              Services and welcome any feedback about third-party clients or
              sites.
            </p>
            <p>
              <b>Third Party Clients.</b> We may collect your personal
              information on behalf of a third party, such as one of our clients
              who is a membership organization. This Privacy Policy does not
              cover our clients’ use of your personal information outside of our
              Sites or Services. We are not responsible for the privacy
              practices of our clients, and therefore encourage you to review
              their respective privacy policies.
            </p>
            <p>
              <b>Third Party Sites.</b> Occasionally, at our discretion, the
              Site and our Services may contain links to third-party sites. Any
              information you provide to, or that is collected by, third-party
              sites may be subject to the privacy policies of those sites. We
              encourage you to reach such privacy policies of any third-party
              sites you visit.
            </p>
            <h4>CALIFORNIA ONLINE PRIVACY PROTECTION ACT COMPLIANCE</h4>
            <p>
              Because we value your privacy, we have taken the necessary
              precautions to comply with the California Online Privacy
              Protection Act. We therefore will not distribute your personal
              information to outside parties without your consent.
            </p>

            <p>
              As part of the California Online Privacy Protection Act, all users
              of our Site or Services may make any changes to their information
              at any time by logging into their control panel and going to the
              ‘Your Account’ page.
            </p>
            <h4>CHILDREN’S ONLINE PRIVACY PROTECTION ACT COMPLIANCE</h4>
            <p>
              We are in compliance with the requirements of COPPA (Children’s
              Online Privacy Protection Act). We do not knowingly collect
              information or market to children under the age of 13. If a parent
              or guardian becomes aware that his or her child has provided us
              with information without their consent, he or she should contact
              us at <a target="_blank">privacy@mhlending.us</a>. We will delete
              such data from our files upon request.
            </p>
            <h4>TERMS OF USE</h4>
            <p>
              Please also visit our Terms of Use section establishing the use,
              disclaimers, and limitations of liability governing the use of our
              Site and Services.
            </p>

            <h4>YOUR CONSENT</h4>
            <p>
              By using our Site or Services, you consent to our Privacy Policy.
            </p>
            <h4>CHANGES TO OUR PRIVACY POLICY</h4>
            <p>
              We may update this Privacy Policy from time to time, and in our
              sole discretion. When we do so, we will post the changes on this
              page and will updated the effective date of the Privacy Policy on
              this page. In all cases, our use of the information we collect is
              subject to the Privacy Policy in effect at the time such
              information is collected.
            </p>

            <p>This policy was last modified on 07/27/2020.</p>
            <h4>CONTACTING US</h4>
            <p>
              If there are any questions regarding this Privacy Policy, you may
              contact us using the information below.
            </p>
            <p>privacy@mhlending.us</p>
            <p>Millennial Home Lending, Inc.</p>
            <p>9200 Oakdale Ave. Ste. 501, Chatsworth, CA 91311</p>
            <p>(818) 812-5150 or (877) 274-2610</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy
